import type React from "react";
import { useEffect, useRef, useState } from "react";
import { GetStartedModal } from "./modals/GetStartedModal";

interface CardProps {
	cardFlavour: string;
	naked: boolean;
	align: "left" | "center" | "right";
	image?: string;
	icon?: React.ReactNode;
	title: string;
	description: React.ReactNode;
}

export const Card: React.FC<{ flavour: string; isScrollTop: boolean }> = ({
	flavour,
	isScrollTop,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const headingRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			setIsVisible(true);
		}, 100);
	}, []);

	const { cardFlavour, naked, align, icon, image, title, description } =
		flavours?.find((item) => item.cardFlavour === flavour) || {};

	const isVideo = (url?: string) => url?.match(/\.(mp4)$/) != null;

	return (
		<div
			className={`card
        ${cardFlavour === "CTA" ? " cta-hero" : ""}
        naked
        ${/*naked ? ' naked' : ' // uncomment this if needed */ false}
        ${align === "right" ? " right" : ""}
        ${cardFlavour === "Flow" ? " flow" : ""}
        ${isScrollTop ? " scroll-top" : ""}
      `}
			ref={headingRef}
		>
			{isVideo(image) ? (
				<video
					autoPlay
					loop
					muted
					playsInline
					className="video-background"
					style={{ opacity: isVisible ? 1 : 0 }}
				>
					<source src={image} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			) : (
				cardFlavour !== "CTA" &&
				cardFlavour !== "Get Started" && (
					<div className={`card-image${isVisible ? " visible" : ""}`}>
						<img src={image} alt="suck-it" />
					</div>
				)
			)}
			<div className={`card-content${naked ? " naked" : ""}`}>
				<h2 className={`fade-in${isVisible ? " visible" : ""}`}>
					{icon}

					{title === "Get more messages on FetLife" ? (
						// <svg viewBox="0 0 640 512" role="graphics-symbol">
						// 	<path d="M224 48a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 96h91.4c65.7 0 120.1 48.7 129 112H49.3c8.9-63.3 63.3-112 129-112zm0-48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zm431 208c17 0 30.7-13.8 30.7-30.7C640 392.2 567.8 320 478.7 320H417.3c-4.4 0-8.8 .2-13.2 .5c46.4 38.6 75.9 96.7 75.9 161.8c0 10.8-2.8 20.9-7.6 29.7H609.3zM432 256c61.9 0 112-50.1 112-112s-50.1-112-112-112c-24.8 0-47.7 8.1-66.3 21.7C377.4 75.9 384 101.2 384 128c0 35.6-11.6 68.5-31.3 95.1C373 243.4 401 256 432 256z" />
						// </svg>

						<svg
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 16 16"
							role="graphics-symbol"
						>
							<path
								className="cls-1"
								d="M15.94,6.49c.03-.25.05-.51.06-.75.01-.46-.01-.91-.07-1.34-.12-.86-.37-1.66-.7-2.39-.16-.37-.34-.71-.54-1.05-.1-.17-.2-.33-.31-.49-.11-.16-.22-.31-.34-.46.02.19.03.37.04.56,0,.19,0,.37,0,.55-.01.37-.04.73-.1,1.08-.07.48-.18.94-.34,1.36-.5-.23-1.06-.35-1.64-.35h-8c-.58,0-1.14.13-1.64.35-.15-.43-.26-.89-.33-1.36-.05-.35-.09-.71-.1-1.08,0-.18,0-.37,0-.55,0-.19.02-.37.04-.56-.12.15-.23.3-.34.46-.11.16-.21.32-.31.49-.2.33-.38.68-.54,1.05C.45,2.73.2,3.52.08,4.39c-.06.43-.09.88-.07,1.34,0,.25.03.5.06.75C.02,6.72,0,6.96,0,7.2v4.8C0,14.21,1.79,16,4,16h8c2.21,0,4-1.79,4-4v-4.8c0-.24-.02-.48-.06-.71ZM14.4,12c0,1.32-1.08,2.4-2.4,2.4h-8c-1.32,0-2.4-1.08-2.4-2.4v-4.8c0-1.01.62-1.87,1.5-2.23.28-.11.58-.17.9-.17h8c.32,0,.62.06.9.17.88.36,1.5,1.22,1.5,2.23v4.8ZM7.2,8.8c0,.44-.18.84-.47,1.13s-.69.47-1.13.47c-.88,0-1.6-.72-1.6-1.6,0-.44.18-.84.47-1.13.04-.04.07-.07.11-.1h0c.28-.23.63-.37,1.02-.37.41,0,.78.15,1.06.4.02.02.05.04.07.06.29.29.47.69.47,1.13ZM12,8.8c0,.44-.18.84-.47,1.13s-.69.47-1.13.47c-.88,0-1.6-.72-1.6-1.6,0-.44.18-.84.47-1.13.02-.02.05-.04.07-.06.28-.25.65-.4,1.06-.4s.74.14,1.02.37c.04.03.08.07.11.1.29.29.47.69.47,1.13Z"
							/>
						</svg>
						// <img
						// 	src="/icon48.png"
						// 	alt="logo"
						// 	style={{
						// 		width: "2rem",
						// 		height: "2rem",
						// 	}}
						// />
					) : title === "Kinksters" ? (
						<svg viewBox="0 0 640 512" role="graphics-symbol">
							<path d="M224 48a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 96h91.4c65.7 0 120.1 48.7 129 112H49.3c8.9-63.3 63.3-112 129-112zm0-48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zm431 208c17 0 30.7-13.8 30.7-30.7C640 392.2 567.8 320 478.7 320H417.3c-4.4 0-8.8 .2-13.2 .5c46.4 38.6 75.9 96.7 75.9 161.8c0 10.8-2.8 20.9-7.6 29.7H609.3zM432 256c61.9 0 112-50.1 112-112s-50.1-112-112-112c-24.8 0-47.7 8.1-66.3 21.7C377.4 75.9 384 101.2 384 128c0 35.6-11.6 68.5-31.3 95.1C373 243.4 401 256 432 256z" />
						</svg>
					) : title === "Closing" ? (
						<svg viewBox="0 0 512 512" role="graphics-symbol">
							<path d="M323.8 477.2c-38.2 10.9-78.1-11.2-89-49.4l-5.7-20c-3.7-13-10.4-25-19.5-35l-51.3-56.4c-8.9-9.8-8.2-25 1.6-33.9s25-8.2 33.9 1.6l51.3 56.4c14.1 15.5 24.4 34 30.1 54.1l5.7 20c3.6 12.7 16.9 20.1 29.7 16.5s20.1-16.9 16.5-29.7l-5.7-20c-5.7-19.9-14.7-38.7-26.6-55.5c-5.2-7.3-5.8-16.9-1.7-24.9s12.3-13 21.3-13L448 288c8.8 0 16-7.2 16-16c0-6.8-4.3-12.7-10.4-15c-7.4-2.8-13-9-14.9-16.7s.1-15.8 5.3-21.7c2.5-2.8 4-6.5 4-10.6c0-7.8-5.6-14.3-13-15.7c-8.2-1.6-15.1-7.3-18-15.2s-1.6-16.7 3.6-23.3c2.1-2.7 3.4-6.1 3.4-9.9c0-6.7-4.2-12.6-10.2-14.9c-11.5-4.5-17.7-16.9-14.4-28.8c.4-1.3 .6-2.8 .6-4.3c0-8.8-7.2-16-16-16H286.5c-12.6 0-25 3.7-35.5 10.7l-61.7 41.1c-11 7.4-25.9 4.4-33.3-6.7s-4.4-25.9 6.7-33.3l61.7-41.1c18.4-12.3 40-18.8 62.1-18.8H384c34.7 0 62.9 27.6 64 62c14.6 11.7 24 29.7 24 50c0 4.5-.5 8.8-1.3 13c15.4 11.7 25.3 30.2 25.3 51c0 6.5-1 12.8-2.8 18.7C504.8 238.3 512 254.3 512 272c0 35.3-28.6 64-64 64l-92.3 0c4.7 10.4 8.7 21.2 11.8 32.2l5.7 20c10.9 38.2-11.2 78.1-49.4 89zM32 384c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H32z" />
						</svg>
					) : title === "Message" ? (
						<svg viewBox="0 0 512 512" role="graphics-symbol">
							<path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
						</svg>
					) : title === "Support" ? (
						<svg viewBox="0 0 512 512" role="graphics-symbol">
							<path d="M48 256C48 141.1 141.1 48 256 48s208 93.1 208 208V400.1c0 22.1-17.9 40-40 40L313.6 440c-8.3-14.4-23.8-24-41.6-24H240c-26.5 0-48 21.5-48 48s21.5 48 48 48h32c17.8 0 33.3-9.7 41.6-24l110.4 .1c48.6 0 88.1-39.4 88.1-88V256C512 114.6 397.4 0 256 0S0 114.6 0 256v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256zm112-32V336c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zM80 256v48c0 44.2 35.8 80 80 80h16c17.7 0 32-14.3 32-32V208c0-17.7-14.3-32-32-32H160c-44.2 0-80 35.8-80 80zm272-32c17.7 0 32 14.3 32 32v48c0 17.7-14.3 32-32 32V224zm80 32c0-44.2-35.8-80-80-80H336c-17.7 0-32 14.3-32 32V352c0 17.7 14.3 32 32 32h16c44.2 0 80-35.8 80-80V256z" />
						</svg>
					) : title === "Get Started" ? null : null}

					{title}
				</h2>
				<p className={`fade-in${isVisible ? " visible" : ""} second`}>
					{description}
				</p>

				{cardFlavour === "CTA" && (
					<div className="hero-cta-wrapper">
						<a href="#get-started">
							{/* Add to Chrome — It's Free* */}
							Get started — It's Free *
						</a>
						<small
							style={{
								display: "block",
								fontSize: ".75rem",
							}}
						>
							* <b>7 day free trial</b>, then $19.97 per month.
							<br />
							<i>No card required. Cancel anytime.</i>
						</small>
					</div>
				)}
			</div>
			{cardFlavour === "CTA" && (
				// <img src="/home-screen.png" alt="logo" id="home-screen-image" />
				<img src="/screen-dark.png" alt="logo" id="home-screen-image" />
			)}
			{/* <img
        src="/laptop.png"
        alt="logo"
        id="laptop-image"
        width="auto"
        height="auto"
      /> */}
		</div>
	);
};

// const InboxIcon = () => (
// 	<span className="icon-background" id="inbox-icon">
// 		<svg id="Layer_1" viewBox="0 0 150 122.01" role="graphics-symbol">
// 			<path
// 				className="cls-2"
// 				d="M12.71,50.83c.01-.49.03-.99.03-1.48,0-12.99,0-25.97,0-38.96C12.75,4.46,17.2,0,23.12,0c38.83,0,77.65,0,116.48,0,5.93,0,10.4,4.45,10.4,10.37,0,15.95,0,31.9,0,47.85,0,5.93-4.45,10.39-10.37,10.39-22.34,0-44.68,0-67.03,0-.53,0-1.06.02-1.59.03-.46,0-.92-.03-1.37-.03-15.48,0-30.95,0-46.43,0-6.05,0-10.46-4.43-10.47-10.49,0-2.43-.02-4.86-.03-7.29ZM84.16,36.24c-.18,1.44-.27,2.6-.47,3.74-.14.79-.36,1.58-.64,2.34-1.47,4.07-4.23,5.85-8.5,5.58-2.1-.13-4.1-.64-6.04-1.41-.61-.24-.82-.1-.81.55.03,1.09.06,2.19-.01,3.28-.04.73.27.98.92,1.11,1.87.37,3.75.69,5.64.82,6.64.47,12.26-2.06,15.08-8.54,2.22-5.11,2.42-10.47,1.17-15.85-1.52-6.58-5.55-10.06-11.83-10.43-7.39-.44-12.78,4.69-12.67,12.08.07,4.51,2.2,7.84,6.33,9.52,4.05,1.65,7.89.97,11.13-2.17.15-.14.3-.28.69-.64ZM53.85,36.54c-.12,2.62-.46,5.12-1.75,7.39-1.66,2.92-4.11,4.3-7.53,4.01-2.09-.18-4.12-.57-6.05-1.36-.89-.36-1.1-.11-1.06.77.05.95.07,1.91,0,2.86-.06.8.24,1.11,1,1.26,1.8.35,3.61.65,5.43.8,7.29.59,13.16-2.58,15.68-9.67,1.82-5.09,1.91-10.31.54-15.5-1.6-6.07-5.81-9.4-11.94-9.66-7.3-.31-13.28,5.12-12.31,13.43.47,4.04,2.77,6.95,6.61,8.33,3.92,1.41,7.57.73,10.68-2.2.17-.16.27-.42.69-.45ZM119.12,40.21c1.59,0,3.18-.03,4.76.02.71.02,1.03-.14.97-.92-.06-.74-.07-1.49,0-2.22.08-.82-.25-1-1.02-.99-3.1.04-6.21-.02-9.31.04-.82.01-.99-.24-.97-1,.05-2.47.04-4.94,0-7.41-.01-.94.43-2.16-.2-2.73-.61-.56-1.82-.08-2.76-.18-.93-.1-1.11.31-1.1,1.15.04,3.03,0,6.07.03,9.1.01.8-.17,1.1-1.03,1.08-2.43-.06-4.87-.04-7.3-.01-.94.01-2.15-.41-2.76.18-.66.64-.11,1.88-.24,2.85-.12.93.3,1.1,1.14,1.09,3.1-.05,6.21,0,9.31-.03.71,0,.88.21.88.89-.03,3.1,0,6.21-.03,9.31,0,.78.14,1.18,1.03,1.08.7-.08,1.41-.06,2.11,0,.73.06.96-.16.95-.92-.04-3.14,0-6.28-.04-9.42,0-.72.14-.98.91-.95,1.55.06,3.1.02,4.66.02Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M.03,76.24c2.84,1.6,5.53,3.12,8.23,4.63,10.49,5.9,20.99,11.79,31.47,17.71.74.42,1.25.39,1.98-.02,12.72-7.18,25.45-14.33,38.19-21.49.45-.25.91-.5,1.48-.81,0,.5,0,.84,0,1.18,0,13.27-.05,26.54.04,39.81.02,2.84-1.88,4.77-4.77,4.76-23.97-.06-47.94-.06-71.9,0C1.89,122.02-.02,120.1,0,117.32c.07-13.34.03-26.68.03-40.02,0-.28,0-.56,0-1.06Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M12.71,50.83c.01,2.43.02,4.86.03,7.29.01,6.06,4.42,10.49,10.47,10.49,15.48,0,30.95,0,46.43,0,.46,0,.92.02,1.37.03-3.24,1.91-6.47,3.84-9.72,5.74-6.56,3.84-13.14,7.66-19.69,11.52-.66.39-1.13.4-1.79,0-12.97-7.6-25.96-15.18-38.96-22.74-.59-.34-.86-.67-.84-1.38.05-2.26,0-4.52.02-6.77.02-2.46,1.73-4.17,4.2-4.19,2.82-.02,5.65,0,8.47,0Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M84.16,36.24c-.39.36-.54.5-.69.64-3.24,3.14-7.08,3.82-11.13,2.17-4.13-1.69-6.26-5.01-6.33-9.52-.11-7.39,5.28-12.52,12.67-12.08,6.28.37,10.31,3.85,11.83,10.43,1.24,5.38,1.05,10.74-1.17,15.85-2.82,6.48-8.44,9.01-15.08,8.54-1.9-.13-3.78-.45-5.64-.82-.65-.13-.96-.38-.92-1.11.07-1.09.04-2.19.01-3.28-.02-.66.19-.8.81-.55,1.94.77,3.95,1.28,6.04,1.41,4.27.26,7.03-1.52,8.5-5.58.28-.76.5-1.55.64-2.34.2-1.14.29-2.3.47-3.74ZM72.83,28.22c-.04,1.45.13,2.8.69,4.09,1.67,3.85,6.32,4.46,8.92,1.16,2.06-2.61,1.74-7.57-.65-10.08-2.6-2.74-6.88-1.98-8.37,1.48-.47,1.09-.64,2.23-.59,3.35Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M53.85,36.54c-.42.03-.52.29-.69.45-3.11,2.93-6.76,3.61-10.68,2.2-3.84-1.38-6.13-4.3-6.61-8.33-.97-8.31,5.01-13.74,12.31-13.43,6.12.26,10.34,3.58,11.94,9.66,1.37,5.19,1.28,10.41-.54,15.5-2.53,7.09-8.4,10.26-15.68,9.67-1.83-.15-3.63-.45-5.43-.8-.76-.15-1.06-.46-1-1.26.07-.95.06-1.91,0-2.86-.05-.88.16-1.13,1.06-.77,1.93.79,3.96,1.18,6.05,1.36,3.41.29,5.87-1.09,7.53-4.01,1.29-2.27,1.63-4.77,1.75-7.39ZM53.57,28.75c-.03-1.01-.22-2.28-.77-3.5-1.12-2.49-3.09-3.7-5.7-3.46-2.13.2-3.78,1.9-4.3,4.47-.41,2.01-.3,4.01.47,5.92.78,1.94,2.14,3.18,4.32,3.38,3.23.31,6.24-2.63,5.98-6.81Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M119.12,40.21c-1.55,0-3.11.05-4.66-.02-.77-.03-.92.23-.91.95.04,3.14,0,6.28.04,9.42.01.76-.22.98-.95.92-.7-.06-1.42-.08-2.11,0-.89.1-1.04-.3-1.03-1.08.04-3.1,0-6.21.03-9.31,0-.68-.17-.9-.88-.89-3.1.04-6.21-.01-9.31.03-.84.01-1.26-.16-1.14-1.09.13-.97-.42-2.21.24-2.85.61-.59,1.82-.17,2.76-.18,2.43-.03,4.87-.05,7.3.01.87.02,1.05-.28,1.03-1.08-.04-3.03,0-6.07-.03-9.1-.01-.84.17-1.25,1.1-1.15.94.1,2.15-.38,2.76.18.63.57.19,1.8.2,2.73.03,2.47.04,4.94,0,7.41-.01.77.15,1.02.97,1,3.1-.05,6.21,0,9.31-.04.77,0,1.1.17,1.02.99-.07.73-.07,1.48,0,2.22.07.78-.26.94-.97.92-1.59-.05-3.17-.02-4.76-.02Z"
// 			/>
// 			<path
// 				className="cls-2"
// 				d="M72.83,28.22c-.06-1.12.12-2.26.59-3.35,1.49-3.47,5.78-4.22,8.37-1.48,2.39,2.52,2.71,7.48.65,10.08-2.6,3.3-7.25,2.69-8.92-1.16-.56-1.29-.73-2.64-.69-4.09Z"
// 			/>
// 			<path
// 				className="cls-2"
// 				d="M53.57,28.75c.26,4.18-2.76,7.12-5.98,6.81-2.18-.21-3.54-1.45-4.32-3.38-.77-1.91-.88-3.9-.47-5.92.53-2.57,2.18-4.27,4.3-4.47,2.62-.25,4.59.96,5.7,3.46.55,1.22.74,2.49.77,3.5Z"
// 			/>
// 		</svg>
// 	</span>
// );

const ReadMore = () => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			{!isExpanded && (
				<>
					<span>FetBot gives you an unfair advantage...</span>
					<span>...but don't worry, we won't tell anyone 😜</span>
					<span
						className="hover-dark"
						style={{ display: "inline" }}
						onClick={() => setIsExpanded(true)}
						onKeyDown={() => setIsExpanded(true)}
					>
						Read more
					</span>
				</>
			)}
			<span
				style={{
					transition: "all .2s",
					overflow: "hidden",
					maxHeight: isExpanded ? "100%" : 0,
				}}
			>
				<span>
					We love FetLife, but there were too many inactive profiles, no filters
					for age, gender, role etc. and it was sometimes prohibitive. We
					decided to build a solution, and turn the volume up to 11.
				</span>
				<br />
				<span>
					FetBot automatically searches for profiles that match your criteria
					and sends them a message that you specify. Think of it like a kinky
					personal assistant.
				</span>
				<br />
				<span style={{ fontFamily: "SohneStrong" }}>
					This is one of the most effective tools you'll ever find, and we think
					you should try it.
				</span>
			</span>
		</>
	);
};

const flavours: CardProps[] = [
	{
		// Hero CTA
		cardFlavour: "CTA",
		naked: true,
		align: "left",
		icon: (
			<>
				<span
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "#cbcbcb",
						fontSize: "4rem",
						gap: "2rem",
					}}
				>
					{/* <span
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2rem',
            }}
          >
            <img className="" src="/icon128.png" alt="icon" id="hero-image" />
            <span style={{ color: '#cbcbcb' }}>=</span>
          </span> */}
					{/* <InboxIcon /> */}
				</span>
			</>
		),
		image: "./card-images/test.webp",
		title: "Get more messages on FetLife",
		description: <ReadMore />,
	},
	{
		// Get Started
		cardFlavour: "Get Started",
		naked: true,
		align: "left",
		icon: null,
		image: "./card-images/test.webp",
		title: "Get Started",
		description: <GetStartedModal />,
	},
	{
		// Kinksters
		cardFlavour: "Kinksters",
		naked: true,
		align: "left",
		icon: null,
		image: "Kinksters.png",
		title: "Kinksters",
		description: (
			<p>Set your preferences to target age, gender, role and more!</p>
		),
	},
	{
		// Closing
		cardFlavour: "Closing",
		naked: true,
		align: "left",
		icon: null,
		image: "Closing.png",
		title: "Closing",
		description: (
			<p>Close profiles that are inactive, or don't match certain criteria.</p>
		),
	},
	{
		// Message
		cardFlavour: "Message",
		naked: true,
		align: "left",
		icon: null,
		image: "Message.png",
		title: "Message",
		description: <p>Choose the subject and message you want to send.</p>,
	},
	{
		// Results
		cardFlavour: "Support",
		naked: true,
		align: "left",
		icon: null,
		image: "Settings.png",
		title: "Support",
		description: (
			<p>
				Manage your account settings or{" "}
				<a href="#support" className="big-link">
					contact us
				</a>
				.
			</p>
		),
	},
	{
		// Flow chart
		cardFlavour: "Flow",
		naked: false,
		align: "left",
		title: "Tech that works like magic",
		description: (
			<>
				<span>
					While you’re sleeping, working, eating or playing, FetBot could be
					searching for profiles that match your criteria, and sending them
					whatever message you specify. You’ll have people rolling into your
					inbox on autopilot.
				</span>
				<br />
				<span>
					<b>
						Think of it like a kinky personal assistant that loves getting you
						action.
					</b>
				</span>
			</>
		),
	},
	{
		// Why do we exist?
		cardFlavour: "Why",
		naked: false,
		align: "right",
		title: "Built from the ground up",
		description: (
			<>
				<span>
					<b>Fetlife was inefficient, and it wasn't as fun as it could be.</b>
				</span>
				<br />
				<span style={{ opacity: 0.8 }}>
					There were too many inactive profiles, no filter for age, gender,
					role, relationship status, orientation etc. and it was a tedious
					experience to trawl through pages of kinksters and find the
					relationship or interaction you were looking for. This annoyed some
					users, as they found it too labor intensive.
				</span>
				<br />
				<span>
					<b>We decided to build a solution, and turn the volume up to 11.</b>
				</span>
			</>
		),
	},
	{
		// Locations
		cardFlavour: "Locations",
		naked: false,
		align: "left",
		title: "Wish you were here",
		description: (
			<>
				<span style={{ opacity: 0.8 }}>
					One consideration, when looking to engage with other members of
					Fetlife, is their geographical location. This was another important
					aspect that Fetlife didn’t provide adequate functionality for.
				</span>
				<br />
				<span>
					<b>
						With FetBot, simply set a list of locations you care about, and only
						kinksters in those locations will be messaged.
					</b>
				</span>
			</>
		),
	},
	{
		// Settings
		cardFlavour: "Settings",
		naked: false,
		align: "right",
		title: "Choose your adventure",
		description: (
			<>
				<span style={{ opacity: 0.8 }}>
					We’ve built a sophisticated application that is configurable down to
					the smallest detail. From basic things like age or gender, all the way
					down to whether or not the user has pictures, or videos, or writings.
					You can even specify an activity threshold, for example, profiles that
					have not shown activity in the past 90 days will not be messaged.
				</span>
				<br />
				<span>
					<b>
						FetBot opens, analyses, and then closes or messages thousands of
						profiles non-stop. We do all the heavy lifting for you.
					</b>
				</span>
			</>
		),
	},
	{
		// Stats
		cardFlavour: "Stats",
		naked: false,
		align: "left",
		title: "Supercharge results",
		description: (
			<>
				<span style={{ opacity: 0.8 }}>
					Software works while you sleep, and it never asks for a holiday, or
					even a coffee! If you’re just messaging people manually, you’re
					missing a trick, and you'll only have a fraction of the fun that you
					could.
				</span>
				<br />
				<span>
					<b>No one works harder than FetBot.</b>
				</span>
				<br />
				<span>
					<b>Every morning, you'll wake to new messages in your inbox.</b>
				</span>
			</>
		),
	},
];
