import type React from "react";
import { useState } from "react";

export const AccountModal: React.FC = () => {
	const [isLogin, setIsLogin] = useState(false); // Toggle between login and sign-up
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		try {
			if (isLogin) {
				// Implement login logic here, e.g., Firebase auth
				console.log("Logging in with", email, password);
			} else {
				// Implement sign-up logic here
				console.log("Signing up with", email, password);
			}
			setLoading(false);
		} catch (err) {
			setError("Something went wrong. Please try again.");
			setLoading(false);
		}
	};

	const toggleMode = () => {
		setIsLogin(!isLogin);
		setEmail("");
		setPassword("");
		setError(null); // Clear errors when toggling
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: "2rem",
				// backgroundColor: "var(--button)",
				borderRadius: "8px",
				boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
				width: "100%",
				maxWidth: "400px",
			}}
		>
			<h2
				style={{
					fontSize: "3rem",
					marginBottom: "1rem",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				{isLogin ? "Hello friend" : "Hello stranger"}
			</h2>
			<p style={{ fontSize: "1.25rem" }}>
				Let's get you signed {isLogin ? "in" : "up"}.
			</p>

			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					gap: "1rem",
					marginTop: "2rem",
				}}
			>
				{/* Email Input */}
				<label style={{ display: "flex", flexDirection: "column" }}>
					{/* <b>Email</b> */}
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						placeholder="Email"
						style={{
							// width: "100%",
							backgroundColor: "var(--button)",
							padding: "1rem",
							border: "1px solid var(--border)",
							borderRadius: "4px",
							fontSize: "1rem",
							// marginTop: ".5rem",
						}}
					/>
				</label>

				{/* Password Input */}
				<label style={{ display: "flex", flexDirection: "column" }}>
					{/* <b>Password</b> */}
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						placeholder="Password"
						style={{
							// width: "100%",
							backgroundColor: "var(--button)",
							padding: "1rem",
							border: "1px solid var(--border)",
							borderRadius: "4px",
							fontSize: "1rem",
							// marginTop: ".5rem",
						}}
					/>
				</label>

				{/* Error Message */}
				{error && <p style={{ color: "red", fontSize: "0.9rem" }}>{error}</p>}

				{/* Submit Button */}
				{/* <a
					className="install-now"
					href="https://www.facebook.com"
					target="_blank"
					rel="noreferrer"
				>
					Add to Chrome
				</a> */}
				<button
					type="submit"
					disabled={loading}
					style={{
						backgroundColor: "#007bff",
						color: "#fff",
						border: "none",
						cursor: loading ? "not-allowed" : "pointer",
						background: "var(--red)",
						fontWeight: "bold",
						fontSize: "1rem",
						borderRadius: "1rem",
						textDecoration: "none",
						padding: "1rem 1.5rem",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "0.75rem",
						transition: "all 0.1s",
						boxSizing: "content-box",
						margin: "1rem 0",
					}}
				>
					{loading ? "Processing..." : isLogin ? "Login" : "Create Account"}
				</button>
			</form>

			{/* Toggle Login/Sign-up */}
			<p style={{ marginTop: "1rem", opacity: 0.5, fontSize: ".875rem" }}>
				{isLogin ? "First time?" : "Already have an account?"}{" "}
				<button
					type="button"
					onClick={toggleMode}
					style={{
						background: "none",
						border: "none",
						cursor: "pointer",
						textDecoration: "underline",
						fontSize: ".875rem",
						// color: "var(--red)",
					}}
				>
					{isLogin ? "Create an account" : "Login"}
				</button>
			</p>
		</div>
	);
};
