import type React from "react";
import { useEffect, useCallback } from "react";
import { Modal } from "./modals/Modal";
import { StatusModal } from "./modals/StatusModal";
import { SupportModal } from "./modals/SupportModal";
import { GetStartedModal } from "./modals/GetStartedModal";
import { RoadmapModal } from "./modals/RoadmapModal";
import { TermsModal } from "./modals/TermsModal";
import { PrivacyModal } from "./modals/PrivacyModal";
import { EthicsModal } from "./modals/EthicsModal";
import { AccountModal } from "./modals/AccountModal";

export const Footer = ({
	modal,
	setModal,
}: {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	modal: { isOpen: boolean; content: any } | null;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	setModal: any;
}) => {
	const openModal = useCallback(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(content?: any, newPath?: string) => {
			newPath && window.history.pushState(null, "", newPath);
			setModal({ isOpen: true, content });
		},
		[setModal],
	);
	const path = window.location.pathname.substring(1);

	useEffect(() => {
		if (modal?.isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		if (path === "status") {
			openModal(<StatusModal />);
		} else if (path === "support") {
			openModal(<SupportModal />);
		} else if (path === "get-started") {
			openModal(<GetStartedModal />);
		} else if (path === "roadmap") {
			openModal(<RoadmapModal />);
		} else if (path === "terms") {
			openModal(<TermsModal />);
		} else if (path === "privacy") {
			openModal(<PrivacyModal />);
		} else if (path === "ethics") {
			openModal(<EthicsModal />);
		} else if (path === "account") {
			openModal(<AccountModal />);
		}
	}, [path, modal?.isOpen, openModal]);

	useEffect(() => {
		const handleHashChange = () => {
			const hash = window.location.hash.substring(1);

			switch (hash) {
				case "status":
					openModal(<StatusModal />);
					break;
				case "support":
					openModal(<SupportModal />);
					break;
				case "get-started":
					openModal(<GetStartedModal />);
					break;
				case "roadmap":
					openModal(<RoadmapModal />);
					break;
				case "terms":
					openModal(<TermsModal />);
					break;
				case "privacy":
					openModal(<PrivacyModal />);
					break;
				case "ethics":
					openModal(<EthicsModal />);
					break;
				case "account":
					openModal(<AccountModal />);
					break;
				default:
					break;
			}
		};

		window.addEventListener("hashchange", handleHashChange);
		handleHashChange();

		return () => {
			window.removeEventListener("hashchange", handleHashChange);
		};
	}, [openModal]);

	return (
		<>
			<div className="footer-wrapper">
				<div className="footer">
					<div className="footer-links">
						<div className="header-actions" style={{ marginBottom: "1rem" }}>
							<StatusButton
								onClick={() => openModal(<StatusModal />, "status")}
							/>
						</div>

						{/* <FooterLink
              title="Get Started"
              onClick={() => openModal(<GetStartedModal />, 'get-started')}
            />
            <FooterLink
              title="Support"
              onClick={() => openModal(<SupportModal />, 'support')}
            /> */}

						<div
							className="legal-links"
							style={{
								flexWrap: "wrap",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: ".5rem",
							}}
						>
							<MiniLink
								title="Roadmap"
								onClick={() => openModal(<RoadmapModal />, "roadmap")}
							/>
							<small style={{ color: "#777" }}>|</small>
							<MiniLink
								title="Terms"
								onClick={() => openModal(<TermsModal />, "terms")}
							/>
							<small style={{ color: "#777" }}>|</small>
							<MiniLink
								title="Privacy"
								onClick={() => openModal(<PrivacyModal />, "privacy")}
							/>
							<small style={{ color: "#777" }}>|</small>
							<MiniLink
								title="Ethics"
								onClick={() => openModal(<EthicsModal />, "ethics")}
							/>
						</div>
					</div>

					<div className="stripe-badge">
						<a
							href="https://stripe.com"
							target="_blank"
							rel="noreferrer"
							style={{ height: "2rem" }}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								id="Layer_1"
								data-name="Layer 1"
								viewBox="0 0 150 34"
								height="2rem"
							>
								<title>Powered by Stripe - blurple</title>
								<path
									className="cls-1"
									d="M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z"
								/>
								<path
									className="cls-1"
									d="M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z"
								/>
								<path
									className="cls-1"
									d="M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z"
								/>
								<polygon
									className="cls-1"
									points="36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35"
								/>
								<path
									className="cls-1"
									d="M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z"
								/>
								<path
									className="cls-1"
									d="M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z"
								/>
								<path
									className="cls-1"
									d="M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z"
								/>
								<path
									className="cls-1"
									d="M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z"
								/>
								<path
									className="cls-1"
									d="M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z"
								/>
								<path
									className="cls-1"
									d="M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z"
								/>
								<path
									className="cls-1"
									d="M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z"
								/>
								<path
									className="cls-1"
									d="M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z"
								/>
								<path
									className="cls-1"
									d="M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z"
								/>
								<path
									className="cls-1"
									d="M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z"
								/>
								<path
									className="cls-1"
									d="M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z"
								/>
								<polygon
									className="cls-1"
									points="116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7"
								/>
								<rect
									className="cls-1"
									x="116.25"
									y="12.61"
									width="2.73"
									height="9.64"
								/>
							</svg>
						</a>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "end",
							alignItems: "center",
							gap: "1rem",
						}}
					>
						<Copyright />
					</div>
				</div>
			</div>

			<Modal modal={modal} setModal={setModal} />
		</>
	);
};

const StatusButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
	<p className="status" onClick={onClick} onKeyDown={onClick}>
		Status
		<span className="status-indicator-wrapper">
			<span className="status-indicator" />
		</span>
	</p>
);

// const FooterLink: React.FC<{ title: string; onClick: () => void }> = ({
// 	title,
// 	onClick,
// }) => (
// 	<p className="roadmap-link" onClick={onClick}>
// 		<svg height="1rem" viewBox="0 0 256 512" fill="var(--text)">
// 			<path d="" />
// 			<path
// 				style={{ opacity: 0.4 }}
// 				d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"
// 			/>
// 		</svg>
// 		{title}
// 	</p>
// );

const MiniLink: React.FC<{ title: string; onClick: () => void }> = ({
	title,
	onClick,
}) => (
	<small onClick={onClick} onKeyDown={onClick} className="link">
		{title}
	</small>
);

const Copyright = () => (
	<small
		className="copyright"
		style={{
			flexWrap: "wrap",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			gap: ".5rem",
		}}
	>
		<span>Copyright © 2098</span>

		<svg
			viewBox="0 0 16 16"
			style={{
				width: "1rem",
				height: "1rem",
				fill: "var(--text)",
				background: "var(--button-hover)",
				padding: "0.5rem",
				borderRadius: ".5rem",
			}}
			role="graphics-symbol"
		>
			<path d="M15.94,6.49c.03-.25.05-.51.06-.75.01-.46-.01-.91-.07-1.34-.12-.86-.37-1.66-.7-2.39-.16-.37-.34-.71-.54-1.05-.1-.17-.2-.33-.31-.49-.11-.16-.22-.31-.34-.46.02.19.03.37.04.56,0,.19,0,.37,0,.55-.01.37-.04.73-.1,1.08-.07.48-.18.94-.34,1.36-.5-.23-1.06-.35-1.64-.35h-8c-.58,0-1.14.13-1.64.35-.15-.43-.26-.89-.33-1.36-.05-.35-.09-.71-.1-1.08,0-.18,0-.37,0-.55,0-.19.02-.37.04-.56-.12.15-.23.3-.34.46-.11.16-.21.32-.31.49-.2.33-.38.68-.54,1.05C.45,2.73.2,3.52.08,4.39c-.06.43-.09.88-.07,1.34,0,.25.03.5.06.75C.02,6.72,0,6.96,0,7.2v4.8C0,14.21,1.79,16,4,16h8c2.21,0,4-1.79,4-4v-4.8c0-.24-.02-.48-.06-.71ZM14.4,12c0,1.32-1.08,2.4-2.4,2.4h-8c-1.32,0-2.4-1.08-2.4-2.4v-4.8c0-1.01.62-1.87,1.5-2.23.28-.11.58-.17.9-.17h8c.32,0,.62.06.9.17.88.36,1.5,1.22,1.5,2.23v4.8ZM7.2,8.8c0,.44-.18.84-.47,1.13s-.69.47-1.13.47c-.88,0-1.6-.72-1.6-1.6,0-.44.18-.84.47-1.13.04-.04.07-.07.11-.1h0c.28-.23.63-.37,1.02-.37.41,0,.78.15,1.06.4.02.02.05.04.07.06.29.29.47.69.47,1.13ZM12,8.8c0,.44-.18.84-.47,1.13s-.69.47-1.13.47c-.88,0-1.6-.72-1.6-1.6,0-.44.18-.84.47-1.13.02-.02.05-.04.07-.06.28-.25.65-.4,1.06-.4s.74.14,1.02.37c.04.03.08.07.11.1.29.29.47.69.47,1.13Z" />
		</svg>
	</small>
);
